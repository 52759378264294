import React, { useContext, useEffect, useRef } from "react";
import calendar from "../../assets/img/calendar.svg";
import arrow_down from "../../assets/img/arrowdown.svg";
import SingleTrip from "../../components/SingleTrip/singleTrip";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import axios from "axios";
import { useState } from "react";
import { TokenContext } from "../Main/main";
const Invoice = () => {
  const pdfExportComponent = useRef(null);
  const token = useContext(TokenContext);
  const [startDate, setStartDate] = useState();
  const [invoices, setInvoices] = useState([]);
  const [filter, setFilter] = useState("Date");
  const [status, setStatus] = useState();
  const [startPrice, setStartPrice] = useState();
  const [endPrice, setEndPrice] = useState();
  const [statusID, setStatusID] = useState(1);
  const handleExportWithComponent = (event) => {
    pdfExportComponent.current.save();
  };
  const handleStatus = (id) => {
    setStatus(id);
    axios
      .get(`http://greengo.apteki.uz/api/invoice/?status=${id}`, {
        headers: { Authorization: "token " + token },
      })
      .then((response) => {
        setInvoices(response.data.data);
      });
  };
  const handlePriceFilter = () => {
    axios
      .get(
        `http://greengo.apteki.uz/api/invoice/?trip_rate__gte=${startPrice}&trip_rate__lte=${endPrice}`
      )
      .then((response) => {
        setInvoices(response.data.data);
      });
  };
  const startFilter = (value) => {
    axios
      .get(
        `http://greengo.apteki.uz/api/invoice/?date__gte=${startDate} 00:00&date__lte=${value} 00:00`,
        { headers: { Authorization: "token " + token } }
      )
      .then((res) => {
        console.log(res.data);
        setInvoices(res.data.data);
      });
  };
  useEffect(() => {
    axios
      .get("http://greengo.apteki.uz/api/invoice/", {
        headers: { Authorization: "token " + token },
      })
      .then((response) => {
        console.log(response.data.data);
        setInvoices(response.data.data);
        axios
          .get("http://greengo.apteki.uz/api/invoice-status", {
            headers: { Authorization: "token " + token },
          })
          .then((response) => {
            console.log(response.data);
          });
      });
  }, []);
  const getAverage = () => {
    let avg = 0;
    for (let i = 0; i < invoices.length; i++) {
      avg += Number(invoices[i].trip_rate) / Number(invoices[i].milage);
    }
    return (avg / invoices.length).toFixed(2);
  };
  const getTotalDat = () => {
    let dat = 0;
    for (let i = 0; i < invoices.length; i++) {
      if (invoices[i].board.name === "DAT") {
        dat += Number(invoices[i].trip_rate);
      }
    }
    return dat;
  };
  const getTotalMilage = () => {
    let milage = 0;
    for (let i = 0; i < invoices.length; i++) {
      milage += Number(invoices[i].milage);
    }
    return milage;
  };
  const getTotalAmazon = () => {
    let dat = 0;
    for (let i = 0; i < invoices.length; i++) {
      if (
        invoices[i].board.name === "Amazon" ||
        invoices[i].board.name === "Google"
      ) {
        dat += Number(invoices[i].trip_rate);
      }
    }
    return dat;
  };
  return (
    <>
      <div className="table-grid sm-grid">
        <div className="table-left">
          <div className="table-nav sm">
            <div className="table-nav-left">
              <p className="green-txt-2">Trip History</p>
              <button className="nav-btn green-txt-1">Owner / Driver</button>
            </div>
            <div className="table-nav-mid calendar">
              {filter === "Date" ? (
                <>
                  <div className="start">
                    <span className="sm-txt-2">Start</span>
                    <img src={calendar} alt="" />
                    <div className="date-picker">
                      <input
                        type="date"
                        className="start-end"
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                      <img src={arrow_down} alt="" />
                    </div>
                  </div>
                  <div className="end">
                    <span className="sm-txt-2">End</span>
                    <img src={calendar} alt="" />
                    <div className="date-picker">
                      <input
                        type="date"
                        className="start-end"
                        onChange={(e) => startFilter(e.target.value)}
                      />
                      <img src={arrow_down} alt="" />
                    </div>
                  </div>
                </>
              ) : filter === "Price" ? (
                <>
                  <div className="start">
                    <span className="sm-txt-2">From</span>
                    <input
                      type="number"
                      className="input"
                      onChange={(e) => setStartPrice(e.target.value)}
                      style={{
                        marginLeft: 10,
                        padding: "5px 10px",
                        background: "#f7f7f7",
                      }}
                    />
                  </div>
                  <div className="end">
                    <span className="sm-txt-2">To</span>
                    <input
                      onChange={(e) => setEndPrice(e.target.value)}
                      type="number"
                      className="input"
                      style={{
                        marginLeft: 10,
                        padding: "5px 10px",
                        background: "#f7f7f7",
                      }}
                    />
                  </div>
                  <button className="nav-btn" onClick={handlePriceFilter}>
                    Get data
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => handleStatus(1)}
                    className={status == 1 ? "nav-btn active" : "nav-btn"}
                  >
                    DISPATCHED
                  </button>
                  <button
                    onClick={() => handleStatus(2)}
                    className={status == 2 ? "nav-btn active" : "nav-btn"}
                  >
                    CONF & BOL
                  </button>
                  <button
                    onClick={() => handleStatus(3)}
                    className={status == 3 ? "nav-btn active" : "nav-btn"}
                  >
                    PENDING
                  </button>
                  <button
                    onClick={() => handleStatus(4)}
                    className={status == 4 ? "nav-btn active" : "nav-btn"}
                  >
                    CONFIRMED
                  </button>
                  <button
                    onClick={() => handleStatus(5)}
                    className={status == 5 ? "nav-btn active" : "nav-btn"}
                  >
                    TONU
                  </button>
                  <button
                    onClick={() => handleStatus(6)}
                    className={status == 6 ? "nav-btn active" : "nav-btn"}
                  >
                    INFO NEEDED
                  </button>
                  <button
                    onClick={() => handleStatus(7)}
                    className={status == 7 ? "nav-btn active" : "nav-btn"}
                  >
                    NEXT WEEK
                  </button>
                </>
              )}
            </div>
            <div className="table-nav-right">
              <p className="green-txt-">Sort by</p>
              <div className="nav-btn-group">
                <button
                  className="nav-btn green-txt-1"
                  onClick={(e) => {
                    console.log(e.target.value);
                    setFilter("Date");
                  }}
                >
                  Date
                </button>
                <button
                  className="nav-btn green-txt-1"
                  onClick={(e) => {
                    setFilter("Price");
                  }}
                >
                  Price
                </button>
                <button
                  className="nav-btn green-txt-1"
                  onClick={(e) => {
                    setFilter("Status");
                  }}
                >
                  Status
                </button>
              </div>
            </div>
          </div>
          <PDFExport
            ref={pdfExportComponent}
            margin={{ top: 20, left: 20, right: 20, bottom: 20 }}
            paperSize="A4"
            scale={0.6}
            landscape="true"
          >
            <div className="table-invoice">
              {invoices !== [] ? (
                invoices.map((invoice, index) => {
                  return (
                    <>
                      <SingleTrip
                        key={index}
                        dispatcher={invoice.dispatcher.name}
                        board={invoice.board.name}
                        owner={invoice.owner.name}
                        driver={invoice.driver.name}
                        number={invoice.driver.track_number}
                        fromcity={invoice.origin}
                        fromdate={invoice.date}
                        tocity={invoice.destination}
                        todate={invoice.date_end}
                        pdf={invoice.documents !== [] ? "false" : "true"}
                        milage={Number(invoice.milage).toFixed()}
                        total={
                          Number(invoice.trip_rate) * Number(invoice.milage)
                        }
                        rate={invoice.trip_rate}
                      />
                      <div
                        className="payment-status"
                        style={{
                          background: invoice.status.color,
                          fontSize: 14,
                          marginLeft: "-25px",
                        }}
                      >
                        {invoice.status.name}
                      </div>
                    </>
                  );
                })
              ) : (
                <h1>Fetching datas please wait</h1>
              )}
            </div>
            <div className="invoice-grid">
              <div className="gross">
                <div className="gross-left">
                  <div className="gross-dat">
                    <p className="green-txt-1">DAT Gross: {getTotalDat()}$</p>
                  </div>
                  <div className="gross-amazon">
                    <p className="green-txt-1">
                      Amazon Gross: {getTotalAmazon()}$
                    </p>
                  </div>
                </div>
                <div className="gross-total">
                  <p>Total Miles: {getTotalMilage()}</p>
                  <h1>Total Gross: {getTotalDat() + getTotalAmazon()}$</h1>
                  <p>Average per mile: {getAverage()}$</p>
                </div>
              </div>
              <div className="expence-payout">
                <div className="expences">
                  <h1>Expences</h1>
                </div>
                <div className="expence-types">
                  <div>Company Fee 10%</div>
                  <div>INSURANCE</div>
                  <div>LOGBOOK</div>
                  <div>TOTAL</div>
                </div>
                <div className="expence-detail">
                  <div>{(getTotalDat() + getTotalAmazon()) / 10}$</div>
                  <div>240$</div>
                  <div>125$</div>
                  <div>
                    {(getTotalDat() + getTotalAmazon()) / 10 + 240 + 125}
                  </div>
                </div>
                <div className="payout">
                  <h1>Payout:</h1>
                </div>
                <div className="total-payout">
                  <h1>
                    {getTotalDat() +
                      getTotalAmazon() -
                      ((getTotalDat() + getTotalAmazon()) / 10 + 240 + 125)}
                    $
                  </h1>
                </div>
              </div>
            </div>
          </PDFExport>
          <div className="btn-group">
            <button
              onClick={handleExportWithComponent}
              className="btn green-txt-1 nav-btn"
            >
              Save and export as PDF
            </button>
          </div>
        </div>
        <div
          className="table-right"
          style={{ background: "#f3f3f3", padding: 0 }}
        >
          <div className="well">
            <h1 className="green-txt-2">Filter By</h1>
            <div className="btn-group">
              <button className="nav-btn green-txt-2">Dispatcher</button>
              <button className="nav-btn green-txt-2">Loadboard</button>
            </div>
          </div>
          <div className="well">
            <h1 className="green-txt-2">Calculate to owner</h1>
            <div className="">
              <p className="green-txt-2">- DAT Fee</p>
              <p className="green-txt-2">- AMAZON Fee</p>
              <p className="green-txt-2">- INSURANCE</p>
              <p className="green-txt-2">- LogBook</p>
              <p className="green-txt-2">- Fuel 1</p>
              <button className="nav-btn green-txt-2">Add Expences</button>
            </div>
          </div>
          <div className="well">
            <h1 className="green-txt-2">Statement By Miles</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
