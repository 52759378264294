import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import calendar from "../../assets/img/calendar.svg";
import { TokenContext } from "../Main/main";
const CoverEdit = (props) => {
    const token = useContext(TokenContext);
    const [fetching, setFetcing] = useState(true);
    const [invoice, setInvoice] = useState();
    const [dispatchers, setDispatchers] = useState();
    const [boards, setBoards] = useState();
    const [owners, setOwners] = useState();
    const [drivers, setDrivers] = useState();
    const [dispatcher, setDispatcher] = useState();
    const [board, setBoard] = useState();
    const [owner, setOwner] = useState();
    const [driver, setDriver] = useState();
    const [dh, setDH] = useState(0);
    const [origin, setOrigin] = useState();
    const [milage, setMilage] = useState();
    const [destination, setDestination] = useState();
    const [rate, setRate] = useState();
    const [date, setDate] = useState();
    const [time, setTime] = useState();
    const [note, setNote] = useState();
    const handleUpdate = () => {
        axios.put(
            `http://greengo.apteki.uz/api/invoice/${props.match.params.id}/`,
            {
                dispatcher: dispatcher,
                board: board,
                owner: owner,
                driver: driver,
                dh: dh,
                origin: origin,
                milage: milage,
                destination: destination,
                trip_rate: rate,
                notes: note,
                end_date: date + " " + time,
                status: 1,
            },
            { headers: { Authorization: "token " + token } }
        );
    };
    useEffect(() => {
        axios
            .get(
                `http://greengo.apteki.uz/api/invoice/${props.match.params.id}/`,
                { headers: { Authorization: "token " + token } }
            )
            .then((res) => {
                console.log(res.data);
                setInvoice(res.data.data);
                setDH(Number(res.data.data.dh).toFixed());
                setOrigin(res.data.data.origin);
                setDestination(res.data.data.destination);
                setRate(Number(res.data.data.trip_rate).toFixed());
                setMilage(Number(res.data.data.milage).toFixed());
                setDate(res.data.data.date_end);
                setDate(res.data.data.date_end);
                setNote(res.data.data.notes);
                axios
                    .get(
                        "http://greengo.apteki.uz/api/dispatcher/?is_active=true/",
                        {
                            headers: { Authorization: "token " + token },
                        }
                    )
                    .then((response) => {
                        setDispatchers(response.data.data);
                        axios
                            .get(
                                "http://greengo.apteki.uz/api/board/?is_active=true/",
                                {
                                    headers: {
                                        Authorization: "token " + token,
                                    },
                                }
                            )
                            .then((response) => {
                                setBoards(response.data.data);
                                axios
                                    .get(
                                        "http://greengo.apteki.uz/api/owner-operator/",
                                        {
                                            headers: {
                                                Authorization: "token " + token,
                                            },
                                        }
                                    )
                                    .then((response) => {
                                        setOwners(response.data.data);
                                        axios
                                            .get(
                                                "http://greengo.apteki.uz/api/driver/?is_active=true/",
                                                {
                                                    headers: {
                                                        Authorization:
                                                            "token " + token,
                                                    },
                                                }
                                            )
                                            .then((response) => {
                                                setDrivers(response.data.data);
                                                setFetcing(false);
                                            });
                                    });
                            });
                    });
            });
    }, []);
    return (
        <>
            <h1 className="green-txt-2">Editing details</h1>
            <div className="edit-form">
                {fetching ? null : (
                    <>
                        <div className="row">
                            <label htmlFor="dispatch">Dispatcher Name</label>
                            <select
                                name="dispatch"
                                id="dispatch"
                                className="input"
                                onChange={(e) => {
                                    setDispatcher(e.target.value);
                                }}
                            >
                                <option
                                    value=""
                                    className="input"
                                    disabled
                                    selected
                                    hidden
                                >
                                    {invoice.dispatcher.name}
                                </option>
                                {dispatchers.map((dispatcher, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={dispatcher.id}
                                            className="input"
                                        >
                                            {dispatcher.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="row">
                            <label htmlFor="board">Board</label>
                            <select
                                name="board"
                                id="board"
                                className="input"
                                onChange={(e) => {
                                    setBoard(e.target.value);
                                }}
                            >
                                <option
                                    value=""
                                    className="input"
                                    disabled
                                    selected
                                    hidden
                                >
                                    {invoice.board.name}
                                </option>
                                {boards.map((board, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={board.id}
                                            className="input"
                                        >
                                            {board.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="row">
                            <label htmlFor="owner">Owner</label>
                            <select
                                name="owner"
                                id="owner"
                                className="input"
                                onChange={(e) => {
                                    setOwner(e.target.value);
                                }}
                            >
                                <option
                                    value=""
                                    className="input"
                                    disabled
                                    selected
                                    hidden
                                >
                                    {invoice.owner.name}
                                </option>
                                {owners.map((owner, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={owner.id}
                                            className="input"
                                        >
                                            {owner.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="row">
                            <label htmlFor="driver">Driver</label>
                            <select
                                name="driver"
                                id="driver"
                                className="input"
                                onChange={(e) => {
                                    setDriver(e.target.value);
                                }}
                            >
                                <option
                                    value=""
                                    className="input"
                                    disabled
                                    selected
                                    hidden
                                >
                                    {invoice.driver.name}
                                </option>
                                {drivers.map((driver, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={driver.id}
                                            className="input"
                                        >
                                            {driver.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="row">
                            <label htmlFor="dh">D-H</label>
                            <input
                                value={dh}
                                type="text"
                                name="dh"
                                placeholder="D - H"
                                className="input"
                                onChange={(e) => {
                                    setDH(e.target.value);
                                }}
                            />
                        </div>
                        <div className="row">
                            <label htmlFor="origin">Origin</label>
                            <input
                                value={origin}
                                type="text"
                                name="origin"
                                placeholder="Origin"
                                className="input"
                                onChange={(e) => {
                                    setOrigin(e.target.value);
                                }}
                            />
                        </div>
                        <div className="row">
                            <label htmlFor="milage">Milage</label>
                            <input
                                value={milage}
                                type="number"
                                name="milage"
                                placeholder="Milage"
                                className="input"
                                onChange={(e) => {
                                    setMilage(e.target.value);
                                }}
                            />
                        </div>
                        <div className="row">
                            <label htmlFor="destination">Destination</label>
                            <input
                                value={destination}
                                type="text"
                                name="destination"
                                placeholder="destination"
                                className="input"
                                onChange={(e) => {
                                    setDestination(e.target.value);
                                }}
                            />
                        </div>
                        <div className="row">
                            <label htmlFor="rate">Trip rate</label>
                            <input
                                value={rate}
                                type="text"
                                name="rate"
                                placeholder="Trip rate"
                                className="input"
                                onChange={(e) => {
                                    setRate(e.target.value);
                                }}
                            />
                        </div>
                        <div className="row" style={{ marginRight: 5 }}>
                            <label htmlFor="date">Delivery Date</label>
                            <div
                                className="date-picker"
                                style={{
                                    background: "#fff",
                                    width: 320,
                                    padding: 5,
                                }}
                            >
                                <input
                                    style={{
                                        border: "none",
                                        width: 50,
                                        fontSize: 14,
                                    }}
                                    value={date}
                                    type="text"
                                    onFocus={(event) => {
                                        event.target.type = "date";
                                    }}
                                    placeholder="Delivery Date"
                                    className="input start-end"
                                />
                                <img
                                    src={calendar}
                                    style={{ right: 15, top: 15 }}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="row">
                            <label htmlFor="note">Delivery Time</label>
                            <input
                                className="input"
                                type="text"
                                value={time}
                                onFocus={(event) => {
                                    event.target.type = "time";
                                }}
                                placeholder="Delivery Time"
                                onChange={(e) => {
                                    setNote(e.target.value);
                                }}
                            />
                        </div>
                        <div className="row">
                            <label htmlFor="note">Special Notes</label>
                            <input
                                value={note}
                                type="text"
                                name="note"
                                placeholder="Special Notes"
                                className="input"
                                onChange={(e) => {
                                    setNote(e.target.value);
                                }}
                            />
                        </div>
                        <div className="center">
                            <button
                                className="btn btn-cover btn-green-2"
                                onClick={handleUpdate}
                            >
                                Edit
                            </button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
export default CoverEdit;
