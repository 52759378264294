import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { TokenContext } from "../../views/Main/main";
const AddingTrip = () => {
  const token = useContext(TokenContext);
  const [fetching, setFetcing] = useState(true);
  const [dispatchers, setDispatchers] = useState();
  const [boards, setBoards] = useState();
  const [owners, setOwners] = useState();
  const [drivers, setDrivers] = useState();
  const [dispatcher, setDispatcher] = useState();
  const [board, setBoard] = useState();
  const [owner, setOwner] = useState();
  const [driver, setDriver] = useState();
  const [index, setIndex] = useState();
  const [dh, setDH] = useState(0);
  const [origin, setOrigin] = useState();
  const [milage, setMilage] = useState();
  const [destination, setDestination] = useState();
  const [rate, setRate] = useState();
  const [deliveryDate, setDeliveryDate] = useState();
  const [deliveryTime, setDeliveryTime] = useState();
  const [note, setNote] = useState();
  const [weather, setWeather] = useState();
  const api_key = "AIzaSyB8NHCF-5fMix0w2363RhC3V4vcyw8SHSM";
  const [date, setDate] = useState(() =>
    new Date().toLocaleString("default", { month: "long" })
  );
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    axios
      .get("http://greengo.apteki.uz/api/dispatcher/?is_active=true/", {
        headers: { Authorization: "token " + token },
      })
      .then((response) => {
        setDispatchers(response.data.data);
        axios
          .get("http://greengo.apteki.uz/api/board/?is_active=true/", {
            headers: { Authorization: "token " + token },
          })
          .then((response) => {
            setBoards(response.data.data);
            axios
              .get("http://greengo.apteki.uz/api/owner-operator/", {
                headers: {
                  Authorization: "token " + token,
                },
              })
              .then((response) => {
                setOwners(response.data.data);
                axios
                  .get("http://greengo.apteki.uz/api/driver/?is_active=true/", {
                    headers: {
                      Authorization: "token " + token,
                    },
                  })
                  .then((response) => {
                    console.log(response.data.data);
                    setDrivers(response.data.data);
                    axios
                      .get(
                        "http://api.weatherapi.com/v1/current.json?key=0d231b86931241358d491122211409&q=New York&aqi=no"
                      )
                      .then((res) => {
                        setWeather(res.data);
                        setFetcing(false);
                      });
                  });
              });
          });
      });
  }, []);
  const postCover = () => {
    console.log(deliveryDate, deliveryTime);
    axios
      .post(
        "http://greengo.apteki.uz/api/invoice/",
        {
          dispatcher: dispatcher,
          board: board,
          owner: owner,
          driver: driver,
          dh: dh,
          origin: origin,
          milage: milage,
          destination: destination,
          trip_rate: rate,
          notes: note,
          date_end: deliveryDate + " " + deliveryTime,
          status: 1,
        },
        {
          headers: { Authorization: "token " + token },
        }
      )
      .then((res) => {
        window.location.reload();
      });
  };
  return (
    <>
      {fetching === true ? (
        <h1 className="green-txt-1">Please wait</h1>
      ) : (
        <>
          <div className="navigation">
            <div className="nav-btn-group">
              <button
                className="nav-btn green-txt-1 active"
                style={{ margin: 0 }}
              >
                NEW TRIP
              </button>
              <button className="nav-btn green-txt-1" style={{ margin: 0 }}>
                EXPENCES
              </button>
            </div>
            <div className="nav-info">
              <p className="green-txt-1">
                TIME NOW: New York / {weather.location.localtime.split(" ")[1]}
              </p>
              <p className="green-txt-1">
                Weather Condition: {weather.current.condition.text}:{" "}
                {weather.current.feelslike_c}*
              </p>
            </div>
          </div>

          <div className="adding">
            <div className="adding-info">
              <span className="sm-txt-1">Today:</span>
            </div>
            <div className="adding-main">
              <div className="date">
                <p className="month green-txt-2">{date}</p>
                <p className="date green-txt-2">
                  {weather.location.localtime.split(" ")[0].split("-")[2]}
                </p>
                <p className="day green-txt-2">{days[new Date().getDay()]}</p>
              </div>
              <div className="form-group">
                <div className="row">
                  <select
                    name="dispatch"
                    id="dispatch"
                    className="input"
                    onChange={(e) => {
                      setDispatcher(e.target.value);
                    }}
                  >
                    <option value="" className="input" disabled selected hidden>
                      Dispatch Name
                    </option>
                    {dispatchers.map((dispatcher, index) => {
                      return (
                        <option
                          key={index}
                          value={dispatcher.id}
                          className="input"
                        >
                          {dispatcher.name}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    name="board"
                    id="board"
                    className="input"
                    onChange={(e) => {
                      setBoard(e.target.value);
                    }}
                  >
                    <option value="" className="input" disabled selected hidden>
                      Board
                    </option>
                    {boards.map((board, index) => {
                      return (
                        <option key={index} value={board.id} className="input">
                          {board.name}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    name="owner"
                    id="owner"
                    className="input"
                    onChange={(e) => {
                      setOwner(e.target.value);
                    }}
                  >
                    <option value="" className="input" disabled selected hidden>
                      Owner Operator
                    </option>
                    {owners.map((owner, index) => {
                      return (
                        <option key={index} value={owner.id} className="input">
                          {owner.name}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    name="driver"
                    id="driver"
                    className="input"
                    onChange={(e) => {
                      setDriver(e.target.value.split(",")[0]);
                      setIndex(e.target.value.split(",")[1]);
                    }}
                  >
                    <option value="" className="input" disabled selected hidden>
                      Driver's Name
                    </option>
                    {drivers.map((driver, index) => {
                      return (
                        <option
                          key={index}
                          name={index}
                          value={[driver.id, index]}
                          className="input"
                        >
                          {driver.name}
                        </option>
                      );
                    })}
                  </select>
                  <div className="row">
                    <p
                      className="green-txt-2"
                      style={{
                        display: "flex",
                        width: "max-content",
                        margin: "0 10px",
                      }}
                    >
                      <span
                        className="sm-txt-2 italic"
                        style={{ marginRight: 10 }}
                      >
                        Phone Number:{" "}
                      </span>
                      {driver ? drivers[index].phone : "Choose a driver"}
                    </p>
                    <p
                      className="green-txt-2"
                      style={{
                        display: "flex",
                        width: "max-content",
                        margin: "0 10px",
                      }}
                    >
                      <span
                        className="sm-txt-2 italic"
                        style={{ marginRight: 10 }}
                      >
                        Trailer Number:{" "}
                      </span>
                      {driver
                        ? drivers[index].trailer_number
                        : "Choose a driver"}
                    </p>
                    <p
                      className="green-txt-2"
                      style={{
                        display: "flex",
                        width: "max-content",
                        margin: "0 10px",
                      }}
                    >
                      <span
                        className="sm-txt-2 italic"
                        style={{ marginRight: 10 }}
                      >
                        Truck Number:{" "}
                      </span>
                      {driver ? drivers[index].track_number : "Choose a driver"}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <input
                    type="text"
                    name="dh"
                    placeholder="D - H"
                    className="input"
                    onChange={(e) => {
                      setDH(e.target.value);
                    }}
                  />
                  <ReactGoogleAutocomplete
                    className="input"
                    apiKey={api_key}
                    placeholder="Origin"
                    onPlaceSelected={(place) => setOrigin(place)}
                  />
                  <input
                    type="number"
                    min="0"
                    name="milage"
                    placeholder="Milage"
                    className="input"
                    onChange={(e) => {
                      setMilage(e.target.value);
                    }}
                  />
                  <ReactGoogleAutocomplete
                    className="input"
                    apiKey={api_key}
                    placeholder="Destination"
                    onPlaceSelected={(place) => setDestination(place)}
                  />
                  <input
                    type="text"
                    name="rate"
                    placeholder="Trip rate"
                    className="input"
                    onChange={(e) => {
                      setRate(e.target.value);
                    }}
                  />
                  <div>
                    <input
                      style={{ width: 150 }}
                      onFocus={(event) => {
                        event.target.type = "date";
                      }}
                      placeholder="Delivery date"
                      type="text"
                      className="input"
                      onChange={(e) => {
                        setDeliveryDate(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <input
                      style={{ width: 150 }}
                      type="text"
                      onFocus={(event) => {
                        event.target.type = "time";
                      }}
                      placeholder="Delivery Time"
                      className="input"
                      name="datetime"
                      onChange={(e) => {
                        setDeliveryTime(e.target.value);
                      }}
                    />
                  </div>
                  <input
                    type="text"
                    name="notes"
                    placeholder="Special Notes"
                    className="input"
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                  />
                  <button
                    className="btn btn-green-2 btn-cover"
                    onClick={postCover}
                  >
                    Cover
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddingTrip;
