import React from "react";
const Driver = (props) => {
  return (
    <>
      <div className="drivers-body-item">
        <p className="driver-number">{props.truck}</p>
        <p className="driver-table-mid">{props.owner}</p>
        <p className="driver-table-mid">{props.driver}</p>
        <p className="driver-table-mid">{props.phone}</p>
        <p className="driver-table-mid">{props.email}</p>
        <p
          className={
            props.status === "active"
              ? "driver-status active"
              : "driver-status not-active"
          }
        >
          {props.status}
        </p>
      </div>
    </>
  );
};
export default Driver;
