import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/style.css";
import axios from "axios";
import logo from "./assets/img/logo.svg";
import Main from "./views/Main/main";
class App extends Component {
  state = {
    username: "",
    password: "",
    errorMessage: "",
    loggedIn: false,
  };

  handleLogin = (e) => {
    e.preventDefault();
    axios
      .get(
        `http://greengo.apteki.uz/api/login/?username=${this.state.username}&password=${this.state.password}`
      )
      .then((response) => {
        if (response.data.data) {
          localStorage.setItem("token", response.data.data.token);
          this.setState({ loggedIn: true });
        } else if (response.data.status === 403) {
          this.setState({ errorMessage: "Invalid password" });
        } else if (response.data.status === 404) {
          this.setState({ errorMessage: "Given user does not exist" });
        } else {
          this.setState({
            errorMessage: "Something went wrong, please try again later",
          });
        }
      });
  };
  handleClasses = () => {
    if (this.state.errorMessage === "") {
      return "input-auth";
    } else {
      return "input-auth error";
    }
  };
  render() {
    return (
      <BrowserRouter>
        {localStorage.getItem("token") ? (
          <Main token={localStorage.getItem("token")} />
        ) : (
          <div className="auth-grid">
            <div className="auth-side">
              <div className="form-group">
                <h1 className="white-txt-1 heading-auth">
                  Sign in to your account
                </h1>
                <input
                  type="text"
                  placeholder="Username"
                  name="password"
                  className={this.handleClasses()}
                  onChange={(e) => {
                    this.setState({ username: e.target.value });
                  }}
                />
                <input
                  type="password"
                  name="password"
                  className={this.handleClasses()}
                  onChange={(e) => {
                    this.setState({ password: e.target.value });
                  }}
                />
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    transition: "1s",
                  }}
                >
                  {this.state.errorMessage}
                </p>
                <button className="btn-auth" onClick={this.handleLogin}>
                  Sign in
                </button>
              </div>
            </div>
            <div className="slogan-side">
              <img src={logo} alt="" className="logo" />
              <h1 className="green-txt-1">Slogan here</h1>
            </div>
          </div>
        )}
      </BrowserRouter>
    );
  }
}
export default App;
