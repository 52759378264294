import React, { Component, useContext, useEffect, useState } from "react";
import AddingTrip from "../../components/AddingTrip/addingTrip";
import SingleTrip from "../../components/SingleTrip/singleTrip";
import DriverActivity from "../../components/DriverActivity/driverActivity";
import calendar from "../../assets/img/calendar.svg";
import arrow_down from "../../assets/img/arrowdown.svg";
import loupe from "../../assets/img/loupe.svg";
import axios from "axios";
import truck from "../../assets/img/truck.svg";
import user from "../../assets/img/user.svg";
import pin from "../../assets/img/pin.svg";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { TokenContext } from "../Main/main";
import ReactGoogleAutocomplete from "react-google-autocomplete";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    outline: "none",
    justifyContent: "center",
  },
  paper: {
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    boxShadow: theme.shadows[5],
  },
  nav: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#005952",
    padding: 15,
  },
  header: {
    background: "#55ae61",
    padding: 15,
  },
  body: { padding: 15 },
}));

const Trip = () => {
  const classes = useStyles();
  const token = useContext(TokenContext);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [index, setIndex] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState();
  const [fileDrop, setFileDrop] = useState(false);
  const [startDate, setStartDate] = useState();
  const handleOpen = (index, id) => {
    setId(id);
    setIndex(index);
    setOpen(true);
  };
  const startFilter = (value) => {
    axios
      .get(
        `http://greengo.apteki.uz/api/invoice/?date__gte=${startDate} 00:00&date__lte=${value} 00:00`,
        { headers: { Authorization: "token " + token } }
      )
      .then((res) => {
        console.log(res.data);
        setInvoices(res.data.data);
      });
  };
  const handleRemove = () => {
    axios
      .delete(`http://greengo.apteki.uz/api/invoice/${id}/`, {
        headers: { Authorization: "token " + token },
      })
      .then(() => window.location.reload());
  };
  const getTotalMilage = () => {
    let milage = 0;
    for (let i = 0; i < invoices.length; i++) {
      milage += Number(invoices[i].milage);
    }
    return milage;
  };
  const getTotalDat = () => {
    let dat = 0;
    for (let i = 0; i < invoices.length; i++) {
      if (invoices[i].board.name === "DAT") {
        dat += Number(invoices[i].trip_rate);
      }
    }
    return dat;
  };
  const getTotalAmazon = () => {
    let dat = 0;
    for (let i = 0; i < invoices.length; i++) {
      if (invoices[i].board.name === "Amazon") {
        dat += Number(invoices[i].trip_rate);
      }
    }
    return dat;
  };
  const getAverage = () => {
    let avg = 0;
    for (let i = 0; i < invoices.length; i++) {
      avg += Number(invoices[i].trip_rate) / Number(invoices[i].milage);
    }
    return (avg / invoices.length).toFixed(2);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSearch = () => {
    if (searchInput.length > 0) {
      axios
        .get(`http://greengo.apteki.uz/api/invoice/?search=${searchInput}`)
        .then((res) => {
          setInvoices(res.data.data);
        });
    }
  };
  const handleFile = (event) => {
    setFile(event.target.files[0]);
  };
  const handleUpload = () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("invoice_id", id);
    console.log(formData);
    axios
      .post("http://greengo.apteki.uz/api/pdf-file/", formData, {
        headers: {
          Authorization: "token " + token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
      });
  };
  const viewFiles = () => {
    setFileDrop(true);
    axios
      .get(`http://greengo.apteki.uz/api/pdf-file/?invoice_id=${id}`, {
        headers: { Authorization: "token " + token },
      })
      .then((response) => {
        setFiles(response.data.data);
      });
  };
  useEffect(() => {
    axios
      .get("http://greengo.apteki.uz/api/invoice/", {
        headers: { Authorization: "token " + token },
      })
      .then((response) => {
        console.log(response);
        setInvoices(response.data.data);
      });
  }, []);
  return (
    <>
      <AddingTrip />
      <div className="table-grid">
        <div className="table-left">
          <div className="table-nav">
            <div className="table-nav-left">
              <p className="green-txt-2">Trip List</p>
              <span className="search-bar">
                <ReactGoogleAutocomplete
                  apiKey={"AIzaSyB8NHCF-5fMix0w2363RhC3V4vcyw8SHSM"}
                  onPlaceSelected={(place) => setSearchInput(place)}
                  placeholder="Search by id, driver..."
                  className="input"
                />
                <input
                  type="text"
                  placeholder="Search by ID, location, driver..."
                  className="input"
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                />
                <img
                  src={loupe}
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={handleSearch}
                />
              </span>
            </div>
            <div className="table-nav-right calendar">
              <div className="start">
                <span className="sm-txt-2">Start</span>
                <img src={calendar} alt="" />
                <div className="date-picker">
                  <input
                    type="date"
                    className="start-end"
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  <img src={arrow_down} alt="" />
                </div>
              </div>
              <div className="end">
                <span className="sm-txt-2">End</span>
                <img src={calendar} alt="" />
                <div className="date-picker">
                  <input
                    type="date"
                    className="start-end"
                    onChange={(e) => startFilter(e.target.value)}
                  />
                  <img src={arrow_down} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="table-statistics">
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                {id || id == 0 ? (
                  <div className={classes.paper}>
                    <div className={classes.nav}>
                      <p className="white-txt-1">Trip Details</p>
                      <div>
                        <a
                          href={`/edit-cover/${id}`}
                          className="white-txt-1 italic"
                        >
                          Edit
                        </a>
                        <i
                          onClick={handleRemove}
                          className="white-txt-1 italic"
                          style={{
                            marginLeft: 15,
                            cursor: "pointer",
                          }}
                        >
                          Remove
                        </i>
                      </div>
                    </div>
                    <div className={classes.header}>
                      <div className="header-top">
                        <span className="popup-heading">
                          {" "}
                          <img src={truck} alt="" /> Truck |{" "}
                          {invoices[index].driver.track_number}
                        </span>
                        <span className="popup-heading">
                          <img src={user} alt="" />{" "}
                          {invoices[index].driver.name}
                        </span>
                      </div>
                      <div className="header-body">
                        <div className="body-left">
                          <div>
                            <img src={pin} alt="" />
                            <span>
                              <h1 className="popup-heading">
                                {invoices[index].origin}
                              </h1>
                              <p className="popup-txt">
                                {invoices[index].date}
                              </p>
                            </span>
                          </div>
                          <div>
                            <img src={pin} alt="" />
                            <span>
                              <h1 className="popup-heading">
                                {invoices[index].destination}
                              </h1>
                              <p className="popup-txt">
                                {invoices[index].date}
                              </p>
                            </span>
                          </div>
                        </div>
                        <div className="body-right">
                          <h1 className="popup-heading">
                            {Number(invoices[index].trip_rate).toFixed(0)}$
                          </h1>
                          <p className="popup-txt">
                            {(
                              Number(invoices[index].trip_rate) /
                              Number(invoices[index].milage)
                            ).toFixed(2)}
                            $/per mile
                          </p>
                          <a href="" className="popup-txt">
                            Download PDF
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className={classes.body}>
                      {fileDrop === true ? (
                        <>
                          {files.map((file, index) => {
                            return (
                              <>
                                <a
                                  style={{
                                    margin: "0 15px",
                                  }}
                                  className="green-txt-2"
                                  href={file.file}
                                >
                                  File {index + 1}
                                </a>
                              </>
                            );
                          })}
                          <br />
                          <input type="file" onChange={handleFile} />
                          <button
                            onClick={handleUpload}
                            className="btn btn-green-2 btn-cover"
                          >
                            Upload
                          </button>
                        </>
                      ) : (
                        <>
                          <div className="details">
                            <p
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={viewFiles}
                              className="green-txt-1"
                            >
                              View Docs
                            </p>
                            {invoices[index].documents.length < 1 ? (
                              <p className="green-txt-1 red-txt">
                                No PDF Attached
                              </p>
                            ) : null}
                          </div>
                          <div className="details">
                            <p className="green-txt-1">Dispatcher Name</p>
                            <p className="green-txt-1">
                              {invoices[index].dispatcher.name}
                            </p>
                          </div>
                          <div className="details">
                            <p className="green-txt-1">Load Board</p>
                            <p className="green-txt-1">
                              {invoices[index].board.name}
                            </p>
                          </div>
                          <div className="details">
                            <p className="green-txt-1">Owner Name</p>
                            <p className="green-txt-1">
                              {invoices[index].owner.name}
                            </p>
                          </div>
                          <div className="details">
                            <p className="green-txt-1">Driver Name</p>
                            <p className="green-txt-1">
                              {invoices[index].driver.name}
                            </p>
                          </div>

                          <div className="details">
                            <p className="green-txt-1">Truck Number</p>
                            <p className="green-txt-1">
                              {invoices[index].driver.track_number}
                            </p>
                          </div>
                          <div className="details">
                            <p className="green-txt-1">Trailer Number</p>
                            <p className="green-txt-1">1111</p>
                          </div>
                          <div className="details">
                            <p className="green-txt-1">Dead Head</p>
                            <p className="green-txt-1">
                              {Number(invoices[index].dh).toFixed()}
                            </p>
                          </div>
                          <div className="details">
                            <p className="green-txt-1">Loaded Miles</p>
                            <p className="green-txt-1">
                              {Number(invoices[index].milage).toFixed()}
                            </p>
                          </div>
                          <div className="details">
                            <p className="green-txt-1">Special Notes</p>
                            <p className="green-txt-1">
                              {invoices[index].notes}
                            </p>
                          </div>
                          <h1 className="green-txt-2" style={{ fontSize: 24 }}>
                            Makropoint required remınd to driver to accept lınk
                          </h1>
                        </>
                      )}
                    </div>
                  </div>
                ) : null}
              </Fade>
            </Modal>
            {invoices !== [] ? (
              invoices.map((invoice, index) => {
                return (
                  <SingleTrip
                    opener={() => {
                      handleOpen(index, invoice.id);
                    }}
                    key={index}
                    dispatcher={invoice.dispatcher.name}
                    board={invoice.board.name}
                    owner={invoice.owner.name}
                    driver={invoice.driver.name}
                    number={invoice.driver.track_number}
                    fromcity={invoice.origin}
                    fromdate={invoice.date}
                    tocity={invoice.destination}
                    todate={invoice.date_end}
                    pdf={invoice.documents.length > 0 ? "true" : "false"}
                    milage={Number(invoice.milage).toFixed()}
                    total={Number(invoice.trip_rate) * Number(invoice.milage)}
                    rate={invoice.trip_rate}
                  />
                );
              })
            ) : (
              <h1>Fetching datas please wait</h1>
            )}
          </div>
          <div className="table-total" style={{ textAlign: "left" }}>
            <div className="total-nav">
              <h1 className="green-txt-2">Amazon GROSS: {getTotalAmazon()}$</h1>
              <h1 className="green-txt-2">DAT Gross: {getTotalDat()}$</h1>
            </div>
            <div className="total-detail">
              <h1 className="green-txt-2">
                Total gross: {getTotalDat() + getTotalAmazon()}$
              </h1>
              <h1 className="green-txt-2">Total miles: {getTotalMilage()}</h1>
              <h1 className="green-txt-2">Average: {getAverage()}$/mile</h1>
            </div>
          </div>
        </div>
        <div className="table-right">
          <div className="table-nav">
            <h1 className="green-txt-2">Drivers Activity</h1>
            <h1 className="green-txt-2 italic">Schedule new</h1>
          </div>
          <div className="table-statistics">
            <DriverActivity
              date="08/02"
              day="Monday"
              driver="Mark"
              destination="Philadelphia, PA"
              status="Transit Time 12 hours"
            />
            <DriverActivity
              date="08/02"
              day="Monday"
              driver="Mark"
              destination="Philadelphia, PA"
              status="empty"
            />
            <DriverActivity
              date="08/02"
              day="Monday"
              driver="Mark"
              destination="Philadelphia, PA"
              status="off duty"
            />
            <DriverActivity
              date="08/02"
              day="Monday"
              driver="Mark"
              destination="Philadelphia, PA"
              status="waiting"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Trip;
