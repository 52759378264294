import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { TokenContext } from "../Main/main";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        outline: "none",
        justifyContent: "center",
    },
    paper: {
        outline: "none",
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        boxShadow: theme.shadows[5],
        width: "40%",
    },
    nav: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: "#005952",
        padding: 15,
    },
    header: {
        background: "#55ae61",
        padding: 15,
    },
    body: { padding: 15 },
    button: {
        background: "#fff",
        padding: "10px 20px",
        border: "none",
        color: "#005952",
        fontWeight: "bold",
        fontSize: 16,
        cursor: "pointer",
    },
}));
const Docs = () => {
    const classes = useStyles();
    const [openCreate, setOpenCreate] = useState(true);
    const token = useContext(TokenContext);
    const [docsList, setDocsList] = useState();
    const [file, setFile] = useState();
    const [files, setFiles] = useState([]);
    const [docType, setDocType] = useState(1);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState();
    const [docName, setDocName] = useState();
    const handleFile = (event) => {
        setFile(event.target.files[0]);
    };
    const handleClose = () => {
        setOpen(false);
        setOpenCreate(false);
    };
    const handleOpen = () => {
        setOpen(true);
        setOpenCreate(true);
    };
    const handleUpload = () => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("doc_id", id);
        console.log(formData);
        axios
            .post("http://greengo.apteki.uz/api/document-file/", formData, {
                headers: {
                    Authorization: "token " + token,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                console.log(response.data);
            });
    };
    const viewFiles = (id) => {
        setId(id);
        axios
            .get(`http://greengo.apteki.uz/api/document-file/?doc_id=${id}`, {
                headers: { Authorization: "token " + token },
            })
            .then((response) => {
                setFiles(response.data.data);
            });
    };
    useEffect(() => {
        axios
            .get("http://greengo.apteki.uz/api/documents/?type=" + docType, {
                headers: { Authorization: "token " + token },
            })
            .then((response) => {
                console.log(response.data);
                setDocsList(response.data.data);
            });
    }, [docType]);
    const addDocumentType = () => {
        axios
            .post(
                "http://greengo.apteki.uz/api/documents/",
                {
                    type: docType,
                    name: docName,
                },
                { headers: { Authorization: "token " + token } }
            )
            .then((res) => {
                window.location.reload();
            });
    };
    return (
        <div style={{ textAlign: "left" }}>
            <div
                className="docs-nav"
                style={{ borderBottom: "4px solid #cdcdcd" }}
            >
                <div className="row">
                    <button
                        className={
                            docType == 1
                                ? "nav-btn green-txt-1 active"
                                : "nav-btn green-txt-1"
                        }
                        style={{ marginLeft: 0 }}
                        onClick={() => setDocType(1)}
                    >
                        Documents
                    </button>
                    <button
                        className={
                            docType == 2
                                ? "nav-btn green-txt-1 active"
                                : "nav-btn green-txt-1"
                        }
                        onClick={() => setDocType(2)}
                    >
                        Driver Applicants
                    </button>
                    <button
                        className={
                            docType == 3
                                ? "nav-btn green-txt-1 active"
                                : "nav-btn green-txt-1"
                        }
                        onClick={() => setDocType(3)}
                    >
                        Existing Truck Docs
                    </button>
                </div>
                <button className="nav-btn blue" onClick={handleOpen}>
                    Add more
                </button>
            </div>
            <div className="docs-body">
                {docsList
                    ? docsList.map((doc) => {
                          return (
                              <div className="docs-item">
                                  <i
                                      className="green-txt-2"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => viewFiles(doc.id)}
                                  >
                                      View Docs |{" "}
                                  </i>
                                  <i
                                      style={{ marginLeft: 25 }}
                                      className="green-txt-2"
                                  >
                                      {doc.name}
                                  </i>
                              </div>
                          );
                      })
                    : null}
            </div>
            {id ? (
                <div className="docs-list">
                    <div className="available-docs">
                        {files.map((file, index) => {
                            return (
                                <>
                                    <a
                                        style={{ margin: "0 15px" }}
                                        className="green-txt-2"
                                        href={file.file}
                                    >
                                        File {index + 1}
                                    </a>
                                </>
                            );
                        })}
                        <br />
                        <input type="file" onChange={handleFile} />
                        <button
                            onClick={handleUpload}
                            className="btn btn-green-2 btn-cover"
                        >
                            Upload
                        </button>
                    </div>
                </div>
            ) : null}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openCreate}>
                    <div className={classes.paper}>
                        <div className={classes.body}>
                            <input
                                type="text"
                                placeholder="Name of Document"
                                className="input"
                                onChange={(e) => setDocName(e.target.value)}
                            />
                            <button
                                onClick={addDocumentType}
                                className="btn-skylar"
                                style={{ color: "#fff" }}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};
export default Docs;
