import React from "react";
const DriverActivity = (props) => {
    const setStatus = () => {
        if (props.status === "empty") {
            return "empty";
        } else if (props.status === "waiting") {
            return "waiting unload";
        } else if (props.status === "off duty" || props.status === "covered") {
            return "covered";
        } else {
            return "transit";
        }
    };
    return (
        <>
            <div className={"activity " + setStatus()}>
                <div className="activity-inner">
                    <p className="white-txt-1">{props.date}</p>
                    <p className="white-txt-1">{props.day}</p>
                </div>
                <div className="activity-inner">
                    <p className="white-txt-1">Driver</p>
                    <p className="activity-info">{props.driver}</p>
                </div>
                <div className="activity-inner">
                    <p className="white-txt-1">Destination</p>
                    <p className="activity-info">{props.destination}</p>
                </div>
                <div className="activity-inner">
                    <p className="activity-info">{props.status}</p>
                </div>
            </div>
        </>
    );
};

export default DriverActivity;
