import React from "react";
import logo from "../../assets/img/logo.png";

const Navigation = () => {
    return (
        <aside>
            <div className="logo">
                <img src={logo} alt="" className="logo-img" />
            </div>
            <div className="link-group">
                <ul className="nav-links">
                    <li className="nav-link active">
                        <a href="/">Dashboard</a>
                    </li>
                    <li className="nav-link">
                        <a href="/invoices">Invoices</a>
                    </li>
                    <li className="nav-link">
                        <a href="/">Statements</a>
                    </li>
                    <details>
                        <summary>Carrier Account</summary>
                        <li className="nav-link">
                            <a href="/">Accounting</a>
                        </li>
                        <li className="nav-link">
                            <a href="/drivers">Driver List</a>
                        </li>
                        <li className="nav-link">
                            <a href="/docs">Documents</a>
                        </li>
                    </details>
                    <li className="nav-link">
                        <a href="/performance">Preformance</a>
                    </li>
                </ul>
            </div>
            <div className="nav-btns">
                <span className="sm-txt">Quick links</span>
                <div className="btn-group">
                    <button className="btn btn-light">Triumph</button>
                    <button className="btn btn-light">EzCOI</button>
                    <button className="btn btn-light">FleetONE</button>
                </div>
            </div>
        </aside>
    );
};
export default Navigation;
