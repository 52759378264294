import React from "react";
import arrow from "../../assets/img/arrow.svg";
const SingleTrip = (props) => {
    return (
        <>
            <div
                onClick={props.opener}
                style={{textAlign: "left"}}
                className={
                    props.pdf === "true"
                        ? "table-trip positive"
                        : "table-trip negative"
                }
            >
                <div className="trip-nav" style={{textAlign: "left"}}>
                    <span className="sm-txt-2" >
                        Dispatcher: {props.dispatcher} | Board: {props.board} |
                        Owner: {props.owner} | Driver: {props.driver}
                    </span>
                </div>
                <div className="trip-body">
                    <div>
                        <span className="trip-number"> {props.number} </span>
                    </div>
                    <div className="trip-destination">
                        <div className="destination">
                            <h1 className="green-txt-2">{props.fromcity}</h1>
                            <span className="sm-txt-2">{props.fromdate}</span>
                        </div>
                        <img src={arrow} alt="" className="trip-arrow" />
                        <div className="destination">
                            <h1 className="green-txt-2">{props.tocity}</h1>
                            <span className="sm-txt-2">{props.todate}</span>
                        </div>
                    </div>
                    <div>
                        <span
                            className={
                                props.pdf === "true"
                                    ? "pdf-positive"
                                    : "pdf-negative"
                            }
                        >
                            {props.pdf === "true"
                                ? "PDF ATTACHED"
                                : "PDF NOT ATTACHED"}
                        </span>
                    </div>
                    <div className="milage">
                        <h1>{props.milage} miles</h1>
                        <span className="sm-txt-2">Total miles:</span>
                    </div>
                    <div className="destination-price">
                        <p className="green-txt-2">
                            {props.rate}$ |{" "}
                            <span className="sm-txt-2">{(Number(props.rate) / Number(props.milage)).toFixed(2)}$ / mi</span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};
export default SingleTrip;
