import React, { useState, useContext, useEffect } from "react";
import arrow_down from "../../assets/img/arrowdown.svg";
import { TokenContext } from "../Main/main";
import user from "../../assets/img/profile-user.svg";
import { Bar } from "@ant-design/charts";
import axios from "axios";
const Performance = () => {
    const token = useContext(TokenContext);
    const [startDate, setStartDate] = useState("2021-01-01");
    const [endDate, setEndDate] = useState(() => {
        let date = new Date().toLocaleDateString().split("/").reverse();
        date = [date[0], date[2], date[1]];
        date = date.join("-");
        return date;
    });
    const [drivers, setDrivers] = useState([]);
    const [dispatchers, setDispatchers] = useState([]);
    const [data, setData] = useState([]);
    const [fetching, setFetching] = useState(true);
    useEffect(() => {
        axios
            .get(
                `http://greengo.apteki.uz/api/preformance/?date_start=${startDate}&date_end=${endDate}`,
                {
                    headers: { Authorization: "token " + token },
                }
            )
            .then((response) => {
                console.log(response);
                setData(response.data);
                axios
                    .get(
                        "http://greengo.apteki.uz/api/driver/?is_active=true",
                        {
                            headers: { Authorization: "token " + token },
                        }
                    )
                    .then((response) => {
                        setDrivers(response.data.data);
                        axios
                            .get(
                                "http://greengo.apteki.uz/api/dispatcher/?is_active=true",
                                {
                                    headers: {
                                        Authorization: "token " + token,
                                    },
                                }
                            )
                            .then((response) => {
                                setDispatchers(response.data.data);
                                setFetching(false);
                            });
                    });
            });
    }, [startDate, endDate]);
    const startFilter = (value) => {
        axios
            .get(
                `http://greengo.apteki.uz/api/preformance/?date_start=${startDate}&date_end=${value}/`,
                { headers: { Authorization: "token " + token } }
            )
            .then((res) => {
                setData(res.data.data);
            });
    };
    const configDis = {
        data: data.dispatcher,
        xField: "avrage",
        yField: "dispatcher",
        barWidthRatio: 0.2,
        label: {
            position: "left",
            layout: [
                { type: "interval-adjust-position" },
                { type: "interval-hide-overlap" },
                { type: "adjust-color" },
            ],
        },
        meta: {
            avrage: { alias: `Average Pay rate` },
            dispatcher: { alias: "Minasa" },
        },
    };
    const configDriver = {
      data: data.driver,
      xField: "avrage",
      yField: "driver",
      barWidthRatio: 0.2,
      label: {
          position: "left",
          layout: [
              { type: "interval-adjust-position" },
              { type: "interval-hide-overlap" },
              { type: "adjust-color" },
          ],
      },
      meta: {
          avrage: { alias: `Average Pay rate` },
          driver: { alias: "Minasa" },
      },
  };
    return (
        <>
            <div className="performance-nav">
                <button
                    className="nav-btn green-txt-2"
                    style={{
                        backgroundColor: "#dddede",
                        padding: "15px 25px",
                        fontSize: 18,
                    }}
                >
                    Performance
                </button>
                <div className="start">
                    <span className="sm-txt-2" style={{ marginRight: 15 }}>
                        Start:
                    </span>
                    <div className="date-picker">
                        <input
                            type="date"
                            className="start-end"
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                        <img src={arrow_down} alt="" />
                    </div>
                </div>
                <div className="end">
                    <span className="sm-txt-2" style={{ marginRight: 15 }}>
                        End:
                    </span>
                    <div className="date-picker">
                        <input
                            type="date"
                            className="start-end"
                            onChange={(e) => startFilter(e.target.value)}
                        />
                        <img src={arrow_down} alt="" />
                    </div>
                </div>
            </div>
            {fetching === false ? (
                <>
                    <div className="performance-main">
                        <h3 className="green-txt-2">Account summary</h3>
                        <div className="performance-numbers">
                            <div className="number">
                                <h3 className="green-txt-2">DAT Load Board</h3>
                                <div className="well">
                                    <h1 className="green-txt-2">
                                        {data.board[1].summa}$
                                    </h1>
                                </div>
                            </div>
                            <div className="number">
                                <h3 className="green-txt-2">AMAZON</h3>
                                <div className="well">
                                    <h1 className="green-txt-2">
                                        {data.board[0].summa}$
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="performance-graph">
                        <h3 className="green-txt-2">
                            Dispatchers' Performance
                        </h3>
                        <div className="graph">
                            <div className="graph-chart">
                                <Bar {...configDis} />
                            </div>
                        </div>
                    </div>
                    <div className="performance-graph">
                        <h3 className="green-txt-2">Drivers' Performance</h3>
                        <div className="graph">
                            <div className="graph-chart">
                                <Bar {...configDriver} />
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};
export default Performance;