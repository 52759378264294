import React, { useContext, useState } from "react";
import { TokenContext } from "../Main/main";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useEffect } from "react";
import Driver from "../../components/Driver/driver";
import axios from "axios";
import truck from "../../assets/img/truck.svg";
import user from "../../assets/img/user.svg";
import phone from "../../assets/img/phone.svg";
import email from "../../assets/img/email.svg";
const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        outline: "none",
        justifyContent: "center",
    },
    paper: {
        outline: "none",
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        boxShadow: theme.shadows[5],
        width: "40%",
    },
    nav: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: "#005952",
        padding: 15,
    },
    header: {
        background: "#55ae61",
        padding: 15,
    },
    body: { padding: 15 },
    button: {
        background: "#fff",
        padding: "10px 20px",
        border: "none",
        color: "#005952",
        fontWeight: "bold",
        fontSize: 16,
        cursor: "pointer",
    },
}));
const Drivers = () => {
    const classes = useStyles();
    const [selectedList, setSelectedList] = useState("driver");
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const [id, setId] = useState(null);
    const [index, setIndex] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [driverName, setDriverName] = useState();
    const [driverEmail, setDriverEmail] = useState();
    const [driverPhone, setDriverPhone] = useState();
    const [driverTruck, setDriverTruck] = useState();
    const [driverOwner, setDriverOwner] = useState();
    const token = useContext(TokenContext);
    const handleOpen = (index, id) => {
        setId(id);
        setDriverName(data[index].name);
        setDriverEmail(data[index].email);
        setDriverPhone(data[index].phone);
        setDriverTruck(data[index].track_number);

        setIndex(index);
        setOpen(true);
    };
    const handlePut = () => {
        axios
            .put(
                `http://greengo.apteki.uz/api/${selectedList}/${id}/`,
                {
                    name: driverName,
                    email: driverEmail,
                    phone: driverPhone,
                    track_number: driverTruck,
                },
                {
                    headers: { Authorization: "token " + token },
                }
            )
            .then(() => window.location.reload());
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleRemove = () => {
        axios
            .put(
                `http://greengo.apteki.uz/api/driver/${id}/`,
                {
                    is_active: false,
                },
                {
                    headers: { Authorization: "token " + token },
                }
            )
            .then(() => window.location.reload());
    };
    const handleEdit = () => {
        setEditMode(!editMode);
    };
    useEffect(() => {
        axios
            .get(`http://greengo.apteki.uz/api/${selectedList}/`, {
                headers: { Authorization: "token " + token },
            })
            .then((response) => {
                console.log(response);
                setData(response.data.data);
            });
    }, [selectedList]);
    const handleCreateDriver = () => {
        axios
            .post(
                `http://greengo.apteki.uz/api/${selectedList}/`,
                {
                    name: driverName,
                    phone: driverPhone,
                    email: driverEmail,
                    track_number: driverTruck,
                },
                {
                    headers: { Authorization: "token " + token },
                }
            )
            .then(() => window.location.reload());
    };
    const handleCreateDispatcher = () => {
        axios
            .post(
                `http://greengo.apteki.uz/api/${selectedList}/`,
                {
                    name: driverName,
                },
                {
                    headers: { Authorization: "token " + token },
                }
            )
            .then(() => window.location.reload());
    };
    const handleClassDriver = () => {
        if (selectedList === "driver") return " active";
        else return "";
    };
    const handleClassDispetcher = () => {
        if (selectedList === "dispatcher") return " active";
        else return "";
    };
    return (
        <div className="drivers">
            <div className="navigation">
                <div className="nav-btn-group">
                    <button
                        className={"nav-btn green-txt-1" + handleClassDriver()}
                        onClick={() => {
                            setSelectedList("driver");
                        }}
                    >
                        DRIVERS
                    </button>
                    <button
                        className={
                            "nav-btn green-txt-1" + handleClassDispetcher()
                        }
                        onClick={() => {
                            setSelectedList("dispatcher");
                        }}
                    >
                        DISPATCHERS
                    </button>
                </div>
                <div className="nav-info">
                    <button
                        className="btn-skylar white-txt-1"
                        onClick={() => setOpenCreate(true)}
                    >
                        SET UP NEW
                    </button>
                </div>
            </div>
            <div className="drivers-table">
                {data.length < 1 ? (
                    <h1>Please wait</h1>
                ) : (
                    <>
                        {selectedList === "driver" ? (
                            <>
                                <div className="drivers-table-head">
                                    <p>Truck number</p>
                                    <p>Owner</p>
                                    <p>Driver name</p>
                                    <p>Phone number</p>
                                    <p>Email</p>
                                    <p>Status</p>
                                </div>
                                <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    className={classes.modal}
                                    open={openCreate}
                                    onClose={() => setOpenCreate(false)}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                >
                                    <Fade in={openCreate}>
                                        <div className={classes.paper}>
                                            <div className={classes.nav}>
                                                <p className="white-txt-1">
                                                    Set up new {selectedList}
                                                </p>
                                            </div>
                                            <div
                                                className={classes.body}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    background: "#cdcdcd",
                                                }}
                                            >
                                                <input
                                                    style={{
                                                        margin: "5px 0",
                                                        width: "50%",
                                                    }}
                                                    onChange={(e) =>
                                                        setDriverName(
                                                            e.target.value
                                                        )
                                                    }
                                                    type="text"
                                                    placeholder="Driver Name"
                                                    className="input"
                                                />
                                                <input
                                                    style={{
                                                        margin: "5px 0",
                                                        width: "50%",
                                                    }}
                                                    onChange={(e) =>
                                                        setDriverEmail(
                                                            e.target.value
                                                        )
                                                    }
                                                    type="text"
                                                    placeholder="Driver Email"
                                                    className="input"
                                                />
                                                <input
                                                    style={{
                                                        margin: "5px 0",
                                                        width: "50%",
                                                    }}
                                                    onChange={(e) =>
                                                        setDriverOwner(
                                                            e.target.value
                                                        )
                                                    }
                                                    type="text"
                                                    placeholder="Driver Owner"
                                                    className="input"
                                                />
                                                <input
                                                    style={{
                                                        margin: "5px 0",
                                                        width: "50%",
                                                    }}
                                                    onChange={(e) =>
                                                        setDriverPhone(
                                                            e.target.value
                                                        )
                                                    }
                                                    type="text"
                                                    placeholder="Driver Phone Number"
                                                    className="input"
                                                />
                                                <input
                                                    style={{
                                                        margin: "5px 0",
                                                        width: "50%",
                                                    }}
                                                    onChange={(e) =>
                                                        setDriverTruck(
                                                            e.target.value
                                                        )
                                                    }
                                                    type="text"
                                                    placeholder="Truck Number"
                                                    className="input"
                                                />
                                                <button
                                                    onClick={handleCreateDriver}
                                                    className="btn btn-green-2"
                                                    style={{
                                                        width: 150,
                                                        height: 35,
                                                        fontSize: 18,
                                                        fontWeight: "regular",
                                                    }}
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        </div>
                                    </Fade>
                                </Modal>
                                <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    className={classes.modal}
                                    open={open}
                                    onClose={handleClose}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                >
                                    {id || id == 0 ? (
                                        <Fade in={open}>
                                            <div className={classes.paper}>
                                                <div className={classes.nav}>
                                                    <p className="white-txt-1">
                                                        Driver Details
                                                    </p>
                                                    <div>
                                                        <i
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={handleEdit}
                                                            className="white-txt-1 italic"
                                                        >
                                                            Edit
                                                        </i>
                                                        <i
                                                            onClick={
                                                                handleRemove
                                                            }
                                                            className="white-txt-1 italic"
                                                            style={{
                                                                marginLeft: 15,
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            Remove
                                                        </i>
                                                    </div>
                                                </div>
                                                <div className={classes.header}>
                                                    {editMode ? (
                                                        <>
                                                            <div className="header-body">
                                                                <span className="popup-heading">
                                                                    Driver Name
                                                                </span>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setDriverName(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    className="input"
                                                                    type="text"
                                                                    value={
                                                                        driverName
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="header-body">
                                                                <span className="popup-heading">
                                                                    Driver Truck
                                                                    Number
                                                                </span>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setDriverTruck(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    className="input"
                                                                    type="text"
                                                                    value={
                                                                        driverTruck
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="header-body">
                                                                <span className="popup-heading">
                                                                    Driver Phone
                                                                </span>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setDriverPhone(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    className="input"
                                                                    type="text"
                                                                    value={
                                                                        driverPhone
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="header-body">
                                                                <span className="popup-heading">
                                                                    Driver Email
                                                                </span>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setDriverEmail(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    className="input"
                                                                    type="text"
                                                                    value={
                                                                        driverEmail
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                className="header-body"
                                                                style={{
                                                                    justifyContent:
                                                                        "center",
                                                                }}
                                                            >
                                                                <button
                                                                    className={
                                                                        classes.button
                                                                    }
                                                                    onClick={
                                                                        handlePut
                                                                    }
                                                                >
                                                                    Finish
                                                                    editing
                                                                </button>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="header-top">
                                                                <span className="popup-heading">
                                                                    {" "}
                                                                    <img
                                                                        src={
                                                                            truck
                                                                        }
                                                                        alt=""
                                                                    />{" "}
                                                                    Truck |{" "}
                                                                    {
                                                                        data[
                                                                            index
                                                                        ]
                                                                            .track_number
                                                                    }
                                                                </span>
                                                                <span className="popup-heading">
                                                                    <img
                                                                        src={
                                                                            user
                                                                        }
                                                                        alt=""
                                                                    />{" "}
                                                                    {
                                                                        data[
                                                                            index
                                                                        ].name
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="header-body">
                                                                <span className="popup-heading">
                                                                    {" "}
                                                                    <img
                                                                        src={
                                                                            email
                                                                        }
                                                                        alt=""
                                                                    />{" "}
                                                                    Email |{" "}
                                                                    {
                                                                        data[
                                                                            index
                                                                        ].email
                                                                    }
                                                                </span>
                                                                <span className="popup-heading">
                                                                    {" "}
                                                                    <img
                                                                        src={
                                                                            phone
                                                                        }
                                                                        alt=""
                                                                    />{" "}
                                                                    Phone |{" "}
                                                                    {
                                                                        data[
                                                                            index
                                                                        ].phone
                                                                    }
                                                                </span>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </Fade>
                                    ) : null}
                                </Modal>
                                <div className="drivers-table-body">
                                    {data.map((data, index) => {
                                        return (
                                            <div
                                                onClick={() =>
                                                    handleOpen(index, data.id)
                                                }
                                            >
                                                <Driver
                                                    key={index}
                                                    truck={data.track_number}
                                                    owner="Owner 1"
                                                    driver={data.name}
                                                    phone={data.phone}
                                                    email={data.email}
                                                    status={
                                                        data.is_active
                                                            ? "active"
                                                            : "not active"
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="drivers-table-head">
                                    <p>Name</p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p>Status</p>
                                </div>
                                <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    className={classes.modal}
                                    open={openCreate}
                                    onClose={() => setOpenCreate(false)}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                >
                                    <Fade in={openCreate}>
                                        <div className={classes.paper}>
                                            <div className={classes.nav}>
                                                <p className="white-txt-1">
                                                    Set up new {selectedList}
                                                </p>
                                            </div>
                                            <div
                                                className={classes.body}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    background: "#cdcdcd",
                                                }}
                                            >
                                                <input
                                                    style={{
                                                        margin: "5px 0",
                                                        width: "50%",
                                                    }}
                                                    onChange={(e) =>
                                                        setDriverName(
                                                            e.target.value
                                                        )
                                                    }
                                                    type="text"
                                                    placeholder="Dispatcher Name"
                                                    className="input"
                                                />
                                                <button
                                                    onClick={
                                                        handleCreateDispatcher
                                                    }
                                                    className="btn btn-green-2"
                                                    style={{
                                                        width: 150,
                                                        height: 35,
                                                        fontSize: 18,
                                                        fontWeight: "regular",
                                                    }}
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        </div>
                                    </Fade>
                                </Modal>
                                <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    className={classes.modal}
                                    open={open}
                                    onClose={handleClose}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                >
                                    {id || id == 0 ? (
                                        <Fade in={open}>
                                            <div className={classes.paper}>
                                                <div className={classes.nav}>
                                                    <p className="white-txt-1">
                                                        Dispatcher Details
                                                    </p>
                                                    <div>
                                                        <i
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={handleEdit}
                                                            className="white-txt-1 italic"
                                                        >
                                                            Edit
                                                        </i>
                                                        <i
                                                            onClick={
                                                                handleRemove
                                                            }
                                                            className="white-txt-1 italic"
                                                            style={{
                                                                marginLeft: 15,
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            Remove
                                                        </i>
                                                    </div>
                                                </div>
                                                <div className={classes.header}>
                                                    {editMode ? (
                                                        <>
                                                            <div className="header-body">
                                                                <span className="popup-heading">
                                                                    Dispatcher
                                                                    Name
                                                                </span>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setDriverName(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    className="input"
                                                                    type="text"
                                                                    value={
                                                                        driverName
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                className="header-body"
                                                                style={{
                                                                    justifyContent:
                                                                        "center",
                                                                }}
                                                            >
                                                                <button
                                                                    className={
                                                                        classes.button
                                                                    }
                                                                    onClick={
                                                                        handlePut
                                                                    }
                                                                >
                                                                    Finish
                                                                    editing
                                                                </button>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="header-body">
                                                                <span className="popup-heading">
                                                                    Dispatcher
                                                                    Name |{" "}
                                                                    {driverName}
                                                                </span>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </Fade>
                                    ) : null}
                                </Modal>
                                <div className="drivers-table-body">
                                    {data.map((data, index) => {
                                        return (
                                            <div
                                                onClick={() =>
                                                    handleOpen(index, data.id)
                                                }
                                            >
                                                <Driver
                                                    key={index}
                                                    truck={data.name}
                                                    status={
                                                        data.is_active
                                                            ? "active"
                                                            : "not active"
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Drivers;
