import React from "react";
import Navigation from "../../components/Navigation/navigation";
import { Switch, Route } from "react-router-dom";
import Trip from "../Trip/trip";
import Drivers from "../Drivers/drivers";
import Invoice from "../Invoice/invoice";
import Performance from "../Performance/performance";
import CoverEdit from "../CoverEdit/coverEdit";
import Docs from "../Docs/docs";
const TokenContext = React.createContext();
const Main = (props) => {
  return (
    <>
      <section className="grid">
        <Navigation />
        <main>
          <nav>
            <span className="md-txt">
              USDOT#3279627 <span className="line">|</span> MC#1036726{" "}
            </span>
          </nav>
          <div className="center">
            <TokenContext.Provider value={props.token}>
              <Switch>
                <Route exact path="/">
                  <Trip />
                </Route>
                <Route exact path="/drivers">
                  <Drivers />
                </Route>
                <Route exact path="/invoices" component={Invoice} />
                <Route exact path="/edit-cover/:id" component={CoverEdit} />
                <Route exact path="/docs" component={Docs} />
                <Route exact path="/performance" component={Performance} />
              </Switch>
            </TokenContext.Provider>
          </div>
        </main>
      </section>
    </>
  );
};

export { TokenContext };
export default Main;
